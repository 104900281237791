
/* eslint-disable */
const getters = {
  cashier: state => {
    return state.cashier
  } ,cashiers: state => {
    return state.cashiers
  },
  cashierTransactions: state =>{
    return state.cashierTransactions
  },
}
export default getters
