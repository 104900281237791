/* eslint-disable */
const actions = {
    getCashierTransactions({ commit ,dispatch}, payload) {
        commit('GET_CASHIER_TRANSACTIONS')
        vm.$http.get(`cashier/transactions/${payload.id}${payload.queryStrings}`).then(response => {
            // commit('SET_CASHIER_TRANSACTIONS', response.data.data)
        })
    },
    getAccounts({ commit }) {
        vm.$http.get('cashier/accounts').then(response => {
            commit('SET_ACCOUNTS', response.data.data)
        })
    },
    getTransactionCategories({ commit }) {
        vm.$http.get('cashier/transactions_categories').then(response => {
            commit('SET_TRANSACTION_CATEGORIES', response.data.data)
        })
    },
    getAccountTransaction({ commit }) {
        vm.$http.get('account').then((response) => {
            commit('ACCOUNT_TRANSACTIONS', response.data.data)
        })
    },
    storeTransaction({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            vm.$http.post(`cashier/newTransaction/${payload.id}`, payload.data).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })

    }
}
export default actions
