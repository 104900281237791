/* eslint-disable */

const actions = {
  initState({commit}){
    commit('initState')
  }
  ,
  getRoles ({commit}) {
    return new Promise((resolve, reject) => {
      vm.$http.get('roles')
        .then(response => {
          commit('getRoles', response.data.roles)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  getRolePermissions({commit}, roleId){
    return new Promise((resolve, reject) => {
      vm.$http.get(`roles/${roleId}`)
        .then(response => {
          commit('getRolePermissions', response.data.rolePermissions)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  }
  ,
  getGroupedPermissions({commit}, args){
    return new Promise((resolve, reject) => {
      let url='permissions'
      if(args[0])
      url=`${url}/${args[0]}`
      if(args[1])
      url=`${url}/${args[1]}`
        vm.$http.get(url)
        .then(response => {
          commit('getPermissions', response.data.permissions)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  storeRole({commit}, role){
    return new Promise((resolve, reject) => {
      vm.$http.post('roles',role
      ).then(response => {
        this.dispatch('role/getRoles')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateRole({commit}, role){
    return new Promise((resolve, reject) => {
      vm.$http.put(`roles/${role.id}`,role
      ).then(response => {
        this.dispatch('role/getRoles')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteRole({commit}, role_id){
    return new Promise((resolve, reject) => {
      vm.$http.delete(`/roles/${role_id}`).then(response => {
        this.dispatch('role/getRoles')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getTrashed({commit}){
    return new Promise((resolve, reject) => {
      vm.$http.get('trashed_roles').then(response => {
        commit('getTrashedRoles', response.data.trashedRoles)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  restoreRole({commit},roleId){
    return new Promise((resolve, reject) => {
      vm.$http.post(`restore_role/${roleId}`).then(response => {
        this.dispatch('role/getTrashed')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPermissions({commit}){
    return new Promise((resolve, reject) => {
      vm.$http.get('permissions/all')
        .then(response => {
          commit('getPermissions', response.data.permissions)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  getModels({commit}){
    return new Promise((resolve, reject) => {
      vm.$http.get('permissions/models')
        .then(response => {
          commit('getModels', response.data.models)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  filterByPermissions({commit}, permissions){
    return new Promise((resolve, reject) => {
      vm.$http.post('roles/filter/by_permissions', {'permissions':permissions} )
        .then(response => {
          commit('getRoles', response.data.roles)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  filterByModels({commit}, models){
    return new Promise((resolve, reject) => {
      vm.$http.post('roles/filter/by_models',{'models':models} )
        .then(response => {
          commit('getRoles', response.data.roles)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default actions
