/* eslint-disable */
const mutations = {
  GET_ACCOUNTS_DATA (state,data) {
    state.accounts = data
  },
  GET_ACCOUNT_DATA (state,data) {
    state.account = data
  },
  GET_ACCOUNT_TRANSACTION_DATA (state,data) {
    state.transactions = data
  }
}
export default mutations
