// axios

/* eslint-disable */
import axios from 'axios'


const domain = process.env.VUE_APP_API_URL
const instance = axios.create({
  baseURL: domain,
})
instance.defaults.withCredentials = true
instance.interceptors.request.use(function (config) {
  if (localStorage.getItem("access_token") && localStorage.getItem("user")) {
    config.headers.common.Authorization = 'Bearer ' + localStorage.getItem("access_token")
    config.headers.common.Accept = "application/json; charset=utf-8; boundary=" + Math.random().toString().substr(2);
  }
  vm.$vs.loading()
  return config
}, function (error) {
  return Promise.reject(error)
});


instance.interceptors.response.use(function (response) {
  if(response.data.message){
    vm.$vs.notify({
          title: 'Success',
          text: vm.$t(response.data.message),
          color: 'success',
          position: 'top-center'
        })
  }
  vm.$vs.loading.close()
  return response;
}, function (error) {
  vm.$vs.loading.close()
  var MessageError
  if (error.response.data) {
    if (error.response.data.message)
      MessageError = error.response.data.message
  } else
    MessageError = 'Unexpected Error'
     if (error.response.status !== 409) {
        vm.$vs.notify({
          title: 'Validation Error',
          text: MessageError,
          color: 'danger',
          position: 'top-center'
        })
     }


  if (error.response.status === 400) {
    vm.$router.push({
      name: 'error-404'
    })
  } else if (error.response.status === 403) {
    vm.$router.push({
      name: 'not-authorized'
    })
  } else if (error.response.status === 401) {
    if (error.response.config.url !== '/auth/login') {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      vm.$router.push({
        name: 'login'
      })
    }
  } else if (error.response.status === 404) {
    vm.$router.push({
      name: 'error-404'
    })
  } else if (error.response.status === 405) {
    vm.$router.push({
      name: 'not-authorized'
    })
  } else if (error.response.status === 500) {
    vm.$router.push({
      name: 'error-500'
    })
  }else if(error.response.status===409){
    vm.$vs.notify({
    title: 'Error!',
    text: vm.$t(error.response.data.error),
    color: 'danger',
    position: 'top-center'
  })
  }
  return Promise.reject(error);
});
export default instance
