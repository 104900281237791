/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth.js'
import moduleRole from './role/moduleRole.js'
import moduleUser from './user/moduleUser.js'
import moduleCashier from "./cashier/moduleCashier"
import modulePaginate from './paginate/modulePaginate.js'
import moduleAccount from './account/moduleAccount'
import moduleUserAccount from "./userAccount/moduleUserAccount"
import moduleTransaction from "@/store/transaction/moduleTransaction";
import moduleCategory from "@/store/transactionCategory/moduleCategory";

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    role :moduleRole,
    user: moduleUser,
    cashier:moduleCashier,
    paginate: modulePaginate,
    account:moduleAccount,
    userAccount: moduleUserAccount,
    transaction: moduleTransaction,
    transactionCategory: moduleCategory
  },
  strict: process.env.NODE_ENV !== 'production'
})
