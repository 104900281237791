/* eslint-disable */
const actions = {
    GetData({ commit, rootGetters, dispatch }) {

        vm.$http.get(`users${rootGetters['paginate/paginateUrl']}`).then((response) => {
            commit('GET_USERS_DATA', response.data.data)
            dispatch('paginate/SetPaginate', response.data.paginate, { root: true })

        })
    },
    GetTrashedData({ commit }) {
        vm.$http.get(`users/trashed`).then((response) => {
            commit('GET_TRASHED_USERS_DATA', response.data.trashedUsers)
        })
    },
    RestoreUser({ dispatch }, user_id) {
        vm.$http.post(`restore_user/${user_id}`).then((response) => {
            dispatch('GetTrashedData')
        })
    },
    GetUserData({ commit }, user_id) {
        vm.$http.get(`users/${user_id}`).then((response) => {
            commit('GET_USER_DATA', response.data.user)
        })


    },
    EditUserData({ commit }, userData) {
        return new Promise((resolve, reject) => {
            vm.$http.put('users/' + userData.id, userData).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    CreateUser({ commit }, userData) {
        return new Promise((resolve, reject) => {
            vm.$http.post('users', userData).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    DeleteUser({ commit, dispatch }, user_id) {
        vm.$http.delete(`users/${user_id}`).then((response) => {
            dispatch('GetData')
        })
    },
    ChangePassword({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            vm.$http.post(`users/change_password/${payload.id}`, payload.password).then(response => {
                dispatch('GetData')
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    EditUserStatus({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            vm.$http.post('users/changeStatus/' + payload.userdata.id, payload.userdata).then(response => {
                dispatch('GetData')
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

}


export default actions
