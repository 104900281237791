/* eslint-disable */
const mutations = {
  initState(state){
    state.rolePermissions = []
  },
  getRoles (state, roles) {
    // state.roles = [
    //   {id: 1, name: 'role_1', permissions_count: 5, models: ['User', 'Blog']},
    //   {id: 2, name: 'role_2', permissions_count: 0, models: []},
    //   {id: 3, name: 'role_3', permissions_count: 3, models: ['Blog']},
    //   {id: 4, name: 'role_4', permissions_count: 7, models: ['Payment', 'Blog', 'User']}
    // ]
    state.roles = roles
  },

  getPermissions(state, permissions){
    state.permissions = permissions
  },
  getRolePermissions(state, rolePermissions){
    state.rolePermissions = rolePermissions
  },
  deleteRole(state, role_id){
    var roleToDelete= state.roles.find(role=>role.id === role_id)
    state.roles.splice(state.roles.findIndex(function (role){
      return JSON.stringify(roleToDelete) === JSON.stringify(role)
    }),1)
  },
  getTrashedRoles(state, trashedRoles){
    state.trashedRoles = trashedRoles
  },
  getModels(state, models){
    state.models = models
  }
}
export default mutations
