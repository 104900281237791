/* eslint-disable */
const mutations = {
  SET_PAGINATE(state, data) {
    state.total = data.total
    state.current_page = data.current_page
    state.firstItem = data.firstItem
    state.lastItem = data.lastItem
  },
  SET_PAGINATE_MODEL(state, data) {
    state.model = data.model
    state.transaction = data.transaction
  },
  SET_PAGINATE_DEFAULT_BEFORE_SEARCH(state) {
    state.total = 0
    state.current_page = 1
    state.firstItem = 0
    state.lastItem = 0
    state.page = 1
  },
  SET_PAGINATE_DEFAULT(state) {
    state.total = 0
    state.current_page = 1
    state.firstItem = 0
    state.lastItem = 0
    state.page = 1
    state.take = 100
    state.search = null
    state.sort = null
    state.sortType = null
  }, SET_PAGINATE_PAGE(state, page) {
    state.page = page
  }, SET_PAGINATE_TAKE(state, take) {
    state.take = take
  }, SET_PAGINATE_SEARCH(state, search) {
    state.search = search
  }, SET_PAGINATE_SORT(state, data) {
    state.sort = data.sort
    state.sortType = data.sortType
  },
}
export default mutations
