<template>
  <div class="this-is-pagination-Table-component">
    <vs-table
      :data="data"
      ref="table"
      :sst="true"
      pagination
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="max_items"
      :total="total"
    >
      <template slot="header">
        <h3>
          {{ table_name }}
        </h3>
        <vs-row class="paginate-header" vs-type="flex" vs-justify="flex-end" vs-w="6">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="2">
            <my-select               class="selectExample"
              v-model="take">
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in [25,50,100]"/>
            </my-select>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1">
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="3">
            <vs-input
              @keyup.enter="handleSearch()"
              icon-pack="feather"
              icon="icon-search"
              :placeholder="$t('Search')"
              v-model="search"
              class="is-label-placeholder"/>
          </vs-col>
        </vs-row>
      </template>
      <slot :data="data"></slot>
    </vs-table>
    <span v-if="model_type!=='account' || transaction" class="select-label-placeholder">
      {{ firstItem }} - {{ lastItem }} of {{ total }}
    </span>
    <span v-else class="select-label-placeholder">
      {{ firstItem }} - {{ hidden_length }} of {{ total }}
    </span>
  </div>
</template>

<script>
import MySelect from '../../../components/MySelect'

/* eslint-disable */
export default {
  name: 'VsPaginationTable',
  props: ['data', 'model_type', 'table_name','hidden_length','transaction'],
  components:{MySelect},

  data() {
    return {
      search: ""
    };
  },

  computed: {
    take: {
      get: function () {
        return this.$store.state.paginate.take
      },
      set: function (newValue) {
        this.$store.dispatch('paginate/ChangeTake', {'take': newValue})
      }
    },
    firstItem() {
      return this.$store.state.paginate.firstItem
    },
    lastItem() {
      return this.$store.state.paginate.lastItem
    },
    max_items() {
      return this.$store.state.paginate.take
    },
    total() {
      return this.$store.state.paginate.total
    }
  },
  methods: {

    handleSearch() {
      this.$store.dispatch('paginate/Search', {'search': this.search})
    },
    handleChangePage(val) {
      this.$store.dispatch('paginate/ChangePage', {'page': val})
    },
    handleSort(sort, sortType) {
      if (sortType) {
        this.$store.dispatch('paginate/Sort', {'sort': sort, 'sortType': sortType})
      } else {
        this.$store.dispatch('paginate/Sort', {'sort': null, 'sortType': null})
      }
    }
  },
  created() {
    this.$store.dispatch('paginate/SetPaginateDefault')
    this.$store.dispatch('paginate/SetModel', {model: this.model_type,transaction : this.transaction||false})
    this.$store.dispatch('paginate/GetData')
  }
};
</script>

