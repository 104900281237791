import state from './moduleCashierState.js'
import mutations from './moduleCashierMutations.js'
import actions from './moduleCashierActions.js'
import getters from './moduleCashierGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
