import state from './moduleUserAccountState.js'
import mutations from './moduleUserAccountMutations.js'
import actions from './moduleUserAccountActions.js'
import getters from './moduleUserAccountGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
