/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../axios'

const actions = {
  /* eslint-disable */
  LoginAction ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('authorize/login', payload
      ).then(response => {
        localStorage.setItem('access_token', response.data.access_token)
        commit('UPDATE_USER_INFO', response.data.user, {root: true})
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SocialLoginAction ({commit}, provider) {
    return new Promise((resolve, reject) => {
      axios.get(`https://demofinance.mgssoftware.net/api/authorize/${provider}/redirect`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SocialLoginCallback ({commit}, payload) {

    return new Promise((resolve, reject) => {
      axios.get(`https://demofinance.mgssoftware.net/api/authorize/${payload.provider}/callback`, {
        params: {
          code: payload.token
        }
      }).then((response) => {
        localStorage.setItem('access_token',response.data.access_token)
        commit('UPDATE_USER_INFO', response.data.user, {root: true})
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
export default actions
