/* eslint-disable */
const actions = {
  getUserInfo({commit}){
    return new Promise((resolve, reject) => {
      vm.$http.get('authorize/my_account')
        .then(response => {
          commit('getUserInfo', response.data.userInfo)
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  },
  saveUserInfo({commit}, userInfo){
    return new Promise((resolve, reject) => {
      vm.$http.post('authorize/my_account',userInfo)
        .then(response => {
          this.dispatch('userAccount/getUserInfo')
          resolve(response)
        }).catch((error) => {
        reject(error)
      })
    })
  }
}
export default actions
