/* eslint-disable */
import axios from "@/axios";

const actions = {
    GetData({ commit, rootGetters, dispatch },payload) {
      if(!payload || payload && !payload.transaction){
         let paginate = payload && payload.nopaginate ? '?nopaginate=true' : rootGetters['paginate/paginateUrl']
        vm.$http.get(`accounts${paginate}`).then((response) => {
            commit('GET_ACCOUNTS_DATA', response.data.accounts)
            if(paginate!=='?nopaginate=true'){
              commit('GET_ACCOUNTS_DATA', response.data.data)
              dispatch('paginate/SetPaginate', response.data.paginate, {root: true})
            }
        })
      }else{
         let paginate = payload && payload.nopaginate ? '?nopaginate=true' : rootGetters['paginate/paginateUrl']
        vm.$http.get(`account/transactions/${payload.transaction}${paginate}`).then((response) => {
            commit('GET_ACCOUNT_TRANSACTION_DATA', response.data.data.data)
            dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
        })
      }

    },
    GetAccountData({ commit }, account_id) {
        vm.$http.get(`accounts/${account_id}`).then((response) => {
            commit('GET_ACCOUNT_DATA', response.data.account)
        })
    },
    CreateAccount({ commit }, accountData) {
        return new Promise((resolve, reject) => {
            vm.$http.post('accounts', accountData).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    EditAccount({ commit }, accountData) {
        return new Promise((resolve, reject) => {
            vm.$http.put('accounts/' + accountData.id, accountData).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    DeleteAccount({ commit }, account_id) {
        vm.$http.delete(`accounts/${account_id}`)
    },
    getAccountTransaction({ commit,rootGetters, dispatch }, data) {
      let paginate = data && data.nopaginate ? '?nopaginate=true' : rootGetters['paginate/paginateUrl']
      let querystrings =''
      if(data.queryStrings){
        querystrings = '&'+data.queryStrings.slice(1)
      }
        vm.$http.get(`account/transactions/${data.account_id}${paginate}${querystrings}`).then((response) => {
            commit('GET_ACCOUNT_TRANSACTION_DATA', response.data.data.data)
            dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
        })
    }

}


export default actions
