

import Vue from 'vue'
import Router from 'vue-router'
import auth from './store/auth/moduleAuth'

Vue.use(Router)

/* eslint-disable */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [

    {
      path: '',
      component: () => import('./layouts/dashboard/Dashboard.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          redirect:'acp/users',
          // component: () => import('./views/pages/dashboard/Dashboard.vue'),
        },

      ]
    },
    {
      path: '/acp',
      component: () => import('./layouts/dashboard/Dashboard.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          redirect:'acp/users',
          // component: () => import('./views/pages/dashboard/Dashboard.vue'),
          meta: {
            // role: 'SuperAdmin',
            // AnyRoles: ['SuperAdmin'],
            // AnyPermissions: ['page2'],
            // AllPermissions: ['page2'],
            // permission: 'page2',
            authRequired: 'true',
          }
        }, {
          path: 'my_account',
          name: 'MyAccount',
          component: () => import('./views/pages/home/UserAccount.vue'),
          meta: {
            // role: 'SuperAdmin',
            // AnyRoles: ['SuperAdmin'],
            // AnyPermissions: ['page2'],
            // AllPermissions: ['page2'],
            // permission: 'page2',
            authRequired: 'true',
          }
        }, {
          path: 'cashiers',
          name: 'Cashiers',
          component: () => import('./views/pages/cashier/list.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          },
        }, {
          path: 'cashiers/:cashierId/details',
          name: 'cashier-details',
          component: () => import('./views/pages/cashier/transactions.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          },
        },
        {
          path: 'accounts',
          name: 'Accounts',
          component: () => import('./views/pages/account/list.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'account/transactions/:accountId',
          name: 'AccountTransactions',
          component: () => import('./views/pages/account/AccountTransactions.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'account/create',
          name: 'CreateAccount',
          component: () => import('./views/pages/account/CreateAccount.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'account/:accountId',
          name: 'EditAccount',
          component: () => import('./views/pages/account/EditAccount.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {

          path: 'users',
          name: 'User',
          component: () => import('./views/pages/user/User.vue'),
          meta: {
            authRequired: 'true',
          }
        },
        {
          path: 'user/create',
          name: 'CreateUser',
          component: () => import('./views/pages/user/CreateUser.vue'),
          meta: {
            authRequired: 'true',
          }
        },
        {
          path: 'user/:userId',
          name: 'EditUser',
          component: () => import('./views/pages/user/EditUser.vue'),
          meta: {
            authRequired: 'true',
          }
        },
        {
          path: 'users/trashed',
          name: 'trashed_users',
          component: () => import('./views/pages/user/TrashedUsers.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'user/:userId/details',
          name: 'UserDetail',
          component: () => import('./views/pages/user/UserDetail.vue'),
          meta: {
            authRequired: 'true',
          }
        },{
          path: 'roles',
          name: 'roles',
          component: () => import('./views/pages/role/Roles.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'trashed',
          name: 'trashed_roles',
          component: () => import('./views/pages/role/TrashedRoles.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'transaction_categories',
          name: 'transaction_categories',
          component: () => import('./views/pages/transactionCategory/list.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'transaction_categories/create',
          name: 'transaction_categories_create',
          component: () => import('./views/pages/transactionCategory/actionsPage.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'transaction_categories/:id/edit',
          name: 'transaction_categories_edit',
          component: () => import('./views/pages/transactionCategory/actionsPage.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'transaction_categories/:id/sub_categories',
          name: 'transaction_categories_sub_categories',
          component: () => import('./views/pages/transactionCategory/subCategoryPage.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        },
        {
          path: 'transaction_categories/trashed',
          name: 'transaction_categories_trashed',
          component: () => import('./views/pages/transactionCategory/trashed.vue'),
          meta: {
            role: 'SuperAdmin',
            authRequired: 'true',
          }
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/auth/Login.vue'),
          meta: {
            UntrustedRequired: 'true',
          }
        },
        {
          path: '/Register',
          name: 'Register',
          component: () => import('@/views/pages/auth/Register.vue'),
          meta: {
            UntrustedRequired: 'true',
          }
        },
        {
          path: 'authorize/:provider/callback',
          name: 'authorize-provider-callback',
          component: () => import('@/views/pages/auth/AuthorizeProviderCallback.vue'),
          meta: {
            UntrustedRequired: 'true',
          }
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/error/Error404.vue')
        },
        {
          path: '/error-500',
          name: 'error-500',
          component: () => import('@/views/pages/error/Error500.vue')
        },
        {
          path: '/coming-soon',
          name: 'coming-soon',
          // component: () => import('@/views/pages/error/ComingSoon.vue')
          component: () => import('@/views/pages/error/Error404.vue')
        },
        {
          path: '/maintenance',
          name: 'maintenance',
          // component: () => import('@/views/pages/error/Maintenance.vue')
          component: () => import('@/views/pages/error/Error404.vue')
        },
        {
          path: '/not-authorized',
          name: 'not-authorized',
          component: () => import('@/views/pages/error/NotAuthorized.vue')
          // component: () => import('@/views/pages/error/Error404.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.UntrustedRequired && auth.getters.isAuthenticated()) {
    return next({name: 'home'})
  }
  if (to.meta.authRequired) {
    if (auth.getters.isAuthenticated()) {
      if (auth.getters.HasRoleAndPermission(to.meta)) {
        return next();
      }
      return next({name: 'error-500'})
    } else {
      return next({name: 'login'})
    }
  } else if (auth.getters.HasRoleAndPermission(to.meta)) {
    return next();
  } else if (!auth.getters.HasRoleAndPermission(to.meta)) {
    return next({name: 'error-500'})
  }
  return next({name: 'error-404'})

})
export default router
