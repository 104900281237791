/* eslint-disable */


const getters = {
  paginateUrl: state => {
    let filter = "&" ;
    for (let index = 0; index < Object.keys(vm.$route.query).length; index++) {
      filter += Object.keys(vm.$route.query)[index] + '='+ vm.$route.query[Object.keys(vm.$route.query)[index]]+'&' 
    }
    return `?page=${state.page}&take=${state.take}&search=${state.search}&sort=${state.sort}&sortType=${state.sortType}${filter}`;
  }, getModel: state => {
    return state.model;
  },
}

export default getters
