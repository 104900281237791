const getters = {
  roles: state => {
    return state.roles
  },
  permissions: state => {
    return state.permissions
  },
  rolePermissions: state => {
    return state.rolePermissions
  },
  trashed: state => {
    return state.trashedRoles
  },
  models: state => {
    return state.models
  }
}
export default getters
