/* eslint-disable */
const actions = {

  SetPaginate({commit}, payload) {
    commit('SET_PAGINATE', payload)
  },
  SetModel({commit}, payload) {
    commit('SET_PAGINATE_MODEL', payload)
  },
  SetPaginateDefault({commit}) {
    commit('SET_PAGINATE_DEFAULT')
  }, SetPaginateDefaultBeforeSearch({commit}) {
    commit('SET_PAGINATE_DEFAULT_BEFORE_SEARCH')
  },
  ChangePage({commit, dispatch}, payload) {
    commit('SET_PAGINATE_PAGE', payload.page)
    dispatch('GetData')
  },

  ChangeTake({commit, dispatch}, payload) {
    commit('SET_PAGINATE_PAGE', 1)
    commit('SET_PAGINATE_TAKE', payload.take)
    dispatch('GetData')
  },
  Search({commit, dispatch}, payload) {
    dispatch('SetPaginateDefaultBeforeSearch')
    commit('SET_PAGINATE_SEARCH', payload.search)
    dispatch('GetData')
  }, Sort({commit, dispatch}, payload) {
    commit('SET_PAGINATE_SORT', {'sort': payload.sort, 'sortType': payload.sortType})
    dispatch('GetData')
  },
  GetData({dispatch, getters,state}) {
    if (getters.getModel)
      dispatch(`${getters.getModel}/GetData`, {transaction:state.transaction}, {root: true})
  }
}


export default actions

