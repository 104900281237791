/* eslint-disable */
import axios from "@/axios";

const actions = {
  GetData({
    commit,
    rootGetters,
    dispatch
  }, payload) {
    if(!payload || payload && !payload.transaction){
      let paginate = payload && payload.nopaginate ? '?nopaginate=true' : rootGetters['paginate/paginateUrl']
      vm.$http.get(`cashiers${paginate}`).then((response) => {
        commit('GET_CASHIERS_DATA', response.data.cashiers)
        if (paginate !== '?nopaginate=true') {
          commit('GET_CASHIERS_DATA', response.data.data)
          dispatch('paginate/SetPaginate', response.data.paginate, {
            root: true
          })
        }
      })
    } else {
      let filter = "&" ;
      for (let index = 0; index < Object.keys(vm.$route.query).length; index++) {
        filter += Object.keys(vm.$route.query)[index] + '='+ vm.$route.query[Object.keys(vm.$route.query)[index]]+'&' 
      }
      vm.$http.get(`cashier/transactions/${payload.transaction}${rootGetters['paginate/paginateUrl']}${filter}`).then(response => {
        commit('SET_CASHIER_TRANSACTIONS', response.data.data)
        dispatch('paginate/SetPaginate', response.data.paginate, {
          root: true
        })
      })
    }

  },

  getCashierTransactions({
    commit,
     rootGetters,
    dispatch
  }, payload) {
      let querystrings = '&'+payload.queryStrings.slice(1)
      if(payload.queryStrings){
        querystrings = '&'+payload.queryStrings.slice(1)
      }
    vm.$http.get(`cashier/transactions/${payload.id}${rootGetters['paginate/paginateUrl']}${querystrings}`).then(response => {
      commit('SET_CASHIER_TRANSACTIONS', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, {
          root: true
        })
    })

  },

  getCashier({
    commit
  }, cashier_id) {
    vm.$http.get(`cashiers/${cashier_id}`).then(response => {
      commit('GET_CASHIER_DATA', response.data.cashier)
    })
  },
  storeCashier({
    commit
  }, cashier) {
    return new Promise((resolve, reject) => {
      axios.post("cashiers", cashier).then(response => {
        this.dispatch('cashier/GetData')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateCashier({
    commit
  }, cashier) {
    return new Promise((resolve, reject) => {
      axios.put(`cashiers/${cashier.id}`, cashier).then(response => {
        this.dispatch('cashier/GetData')
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  DeleteCashier({
    commit
  }, account_id) {
    vm.$http.delete(`cashiers/${account_id}`)
  },
}
export default actions
