
/* eslint-disable */
const state = {
  users: [],
  user: {},
  trashedUsers:[],
  users_paginate: {
    'total': 0,
    'current_page': 1,
    'firstItem': 0,
    'lastItem': 0
  },
}
export default state
