/*eslint-disable*/
const getters ={
  element : state=>{
    return state.element
  },
  data : state=>{
    return state.data
  },
  trashedData : state=>{
    return state.trashedData
  },
  sectionName : state=>{
    return state.sectionName
  },
  sectionNameCategory : state=>{
    return state.sectionNameCategory
  },

}
export default getters
