// en, de, fr, pt

import arLang from './ar.json'
import enLang from './en.json'
import trLang from './tr.json'

const lang = {
  en: enLang,
  tr: trLang,
  ar: arLang
}
export default lang
// grep -Pohr '\$t\(([^)]+)\)'
