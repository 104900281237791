

import state from './PaginateState.js'
import mutations from './PaginateMutations.js'
import actions from './PaginateActions.js'
import getters from './PaginateGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
