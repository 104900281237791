import state from './moduleTransactionState'
import actions from './moduleTransactionActions'
import mutations from './moduleTransactionMutations'
import getters from './moduleTransactionGetters'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
