/* eslint-disable */
const mutations = {
    GET_CASHIERS_DATA(state, data) {
        state.cashiers = data
    },
    GET_CASHIER_DATA(state, data) {
        state.cashier = data
    },
    SET_CASHIER_TRANSACTIONS(state, data){
    state.cashierTransactions = data
  },
}
export default mutations
