/* eslint-disable */


const getters = {

  users: state => {
    return state.users
  },users_paginate: state => {
    return state.users_paginate
  },

  user: state => {
    return state.user
  },
  trashedUsers: state => {
    return state.trashedUsers
  },
  userWithoutPagination: state => {
    return state.users
  },
}

export default getters
