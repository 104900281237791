/* eslint-disable */
const getters = {
  cashierTransactions: state =>{
    return state.cashierTransactions
  },
  accounts: state =>{
    return state.accounts
  },
  transactionCategories: state =>{
    return state.transactionCategories
  },
  accountTransactions: state =>{
    return state.accountTransactions
  },
}
export default getters
