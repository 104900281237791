/*eslint-disable*/
const actions ={
  GetData({commit,rootGetters, dispatch, getters}, payload) {
    let val = getters.sectionName ||  'transaction_categories'
    let url  = getters.sectionNameCategory || val
    let paginate = payload && payload.nopaginate ? '?nopaginate=true' : rootGetters['paginate/paginateUrl']

    vm.$http.get(url+paginate).then((response) => {
      commit('SET_STATE_DATA', response.data.TransactionCategory)
      if(paginate!=='?nopaginate=true'){
        commit('SET_STATE_DATA', response.data.data)
          dispatch('paginate/SetPaginate', response.data.paginate, {root: true})
      }
    })
  },
  GetTrashedData({commit,getters}){
    vm.$http.get(`${getters.sectionName}/trashed`).then(response=>{
      commit('SET_TRASHED_DATA', response.data.data)
    })
  },restore({dispatch,getters}, payload){
    vm.$http.post(`${getters.sectionName}/restore/${payload.id}`).then(()=>{
      dispatch('GetTrashedData')
    })
  }
  ,
  GetElement({commit, getters}, payload){
    return new Promise((resolve, reject) => {
      vm.$http.get(`${getters.sectionName}/${payload.id}`).then(response => {
        commit('SET_STATE_ELEMENT', response.data.data)//only if pops enable
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  Store({ dispatch, getters}){
    let val = getters.sectionName ||  'transaction_categories'
    return new Promise((resolve, reject) => {
      vm.$http.post(`${val}`, getters.element).then(response => {
        if(getters.sectionName){
          dispatch("GetData")//only if pops enable
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  Update({commit,dispatch,getters}, payload){
    return new Promise((resolve, reject) => {
      vm.$http.put(`${getters.sectionName}/${payload.id}`, getters.element).then(response => {
        dispatch("GetData")//only if pops enable
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  Delete({dispatch,getters}, payload){
    vm.$http.delete(`${getters.sectionName}/${payload.id}`).then(()=>{
      dispatch("GetData")
    })
  },
  SetSectionName({commit}, payload){
    if(payload.sectionNameCategory){
      commit('SET_SECTION_NAME_WITH_CATEGORY', payload.sectionNameCategory)
    }else{
      commit('SET_SECTION_NAME', payload.sectionName)
      commit('SET_SECTION_NAME_WITH_CATEGORY', '')
    }
  },
  SetElement({commit}, payload){
    commit('SET_ELEMENT', payload.elementData)
  }
}
export default actions
