

import state from './AccountState.js'
import mutations from './AccountMutations.js'
import actions from './AccountActions.js'
import getters from './AccountGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
