/* eslint-disable */
const mutations = {
   GET_USERS_DATA (state,data) {
      state.users = data
  },
  GET_USER_DATA (state,data) {
     state.user = data
  },
  GET_TRASHED_USERS_DATA (state,data) {
    state.trashedUsers = data
  }
}
export default mutations
