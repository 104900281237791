
/* eslint-disable */
const getters = {
  isAuthenticated: () => {
    return !!localStorage.getItem('access_token');
  },
  GetUser: () => {
    if (this.isAuthenticated()) {
      return localStorage.getItem('user')
    } else
      return []
  },
  GetUserRoles: () => {
    if (this.GetUser() !== []) {
      return this.GetUser().roles
    } else {
      return []
    }
  },
  GetUserPermissions: () => {
    let UserPermissions = []
    if (this.GetUserRoles()!==[]) {
        this.GetUserRoles().forEach((role) => {
          UserPermissions.push(role.permissions)
        })
    }
    return UserPermissions
  },
  HasRoleAndPermission: (Requirement) => {
    let disable = true
    if (disable) {
      return true
    }
    disable = false
    if (!Requirement) {
      return true
    }
    let userRoles = ''
    let userPermissions = ''
    userRoles = this.GetUserRoles()
    userPermissions = this.GetUserPermissions()
    if (userRoles.include('SuperAdmin')) {
      return true
    }
    let AccessGeneratorAnyRole = false
    let AccessGeneratorPermissionRole = false
    let AllPermissions = []
    let AnyPermissions = []
    let AllRoles = []
    let AnyRoles = []
    if (Requirement.AnyRoles) {
      AnyRoles = Requirement.AnyRoles
    }
    if (Requirement.AllRoles) {
      AllRoles = Requirement.AllRoles
    }
    if (Requirement.role) {
      AllRoles.push(Requirement.role)
    }
    if (Requirement.AnyPermissions) {
      AnyPermissions = Requirement.AnyPermissions
    }
    if (Requirement.AllPermissions) {
      AllPermissions = Requirement.AllPermissions
    }
    if (Requirement.permission) {
      AllPermissions.push(Requirement.permission)
    }
    if (AllPermissions.length === 0 && AllRoles.length === 0 && AnyPermissions.length === 0 && AnyRoles.length === 0) {
      return true
    } else {
      AllRoles.forEach((role) => {
        if (!userRoles.include(role)) {
          return false
        }
      })
      AllPermissions.forEach((permission) => {
        if (!userPermissions.include(permission)) {
          return false
        }
      })
      AnyRoles.forEach((role) => {
        if (userRoles.include(role)) {
          AccessGeneratorAnyRole = true
        }
      })
      AnyPermissions.forEach((permission) => {
        if (userPermissions.include(permission)) {
          AccessGeneratorPermissionRole = true
        }
      })
      if (AccessGeneratorAnyRole && AccessGeneratorPermissionRole) {
        return true
      }
    }
    return false
  }
}
export default getters
