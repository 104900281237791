/* eslint-disable */


const getters = {
  accounts: state => {
    return state.accounts
  },
  account: state => {
    return state.account
  },
  transactions: state => {
    return state.transactions
  },
}

export default getters
