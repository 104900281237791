/*eslint-disable*/
const mutations ={
  SET_STATE_DATA (state,data) {
    state.data = data
  },
  SET_STATE_ELEMENT (state,element) {
    state.element = element
  },
  SET_SECTION_NAME(state, sectionName){
    state.sectionName = sectionName
  },
  SET_SECTION_NAME_WITH_CATEGORY(state, sectionNameCategory){
    state.sectionNameCategory = sectionNameCategory
  },
  SET_ELEMENT(state, elementData){
    state.element = elementData
  },
  SET_TRASHED_DATA(state, data){
    state.trashedData = data
  }

}
export default mutations
